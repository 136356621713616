import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'core.ui-module_site-components';
// import { ApplicationContext, SecurityContextHolder } from 'core.ui-module_site-security';
import { SecurityContextHolder } from 'core.ui-module_site-security';

// Use ApplicationContext to get site information such as email, site title, site name, etc
export default class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShow: false,
    };
  }

  toggleShow(isShow) {
    this.setState({ isShow });
  }

  render() {
    const { isShow } = this.state;
    const external = !SecurityContextHolder.isAuthenticated();
    return (
      <Fragment>
        <a
          onClick={() => {
            this.toggleShow(true);
          }}
          className="navbar-item"
        >
          Contact Us
        </a>
        {isShow && (
          <Modal
            header="Contact Us"
            footer={<div />}
            closeModalCallback={() => {
              this.toggleShow(false);
            }}
          >
            <div>
              {/* html/text goes here */}

              {external && <Fragment>{/* Fill in external only html/text here */}</Fragment>}
            </div>
          </Modal>
        )}
      </Fragment>
    );
  }
}
ContactUs.propTypes = {
  external: PropTypes.bool,
};

ContactUs.defaultProps = {
  external: undefined,
};
