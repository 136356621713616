let appStorage;
try {
  appStorage =
    window.opener &&
    window.opener.document &&
    window.opener.document.domain === window.document.domain
      ? window.opener.sessionStorage
      : sessionStorage;
} catch (error) {
  appStorage = sessionStorage;
}

// eslint-disable-next-line import/prefer-default-export
export class AccountInsightsContext {
  static #SKILL_CLIENT_ID_KEY = 'APPL_CTXT.SKILL_CLIENT_ID';

  static #SKILL_REDIRECT_URL_KEY = 'APPL_CTXT.SKILL_REDIRECT_URL';

  static setContext = (key, value) => {
    appStorage.setItem(key, value);
  };

  static getContext = (key, defaultValue) => {
    const value = appStorage.getItem(key);
    return value || defaultValue;
  };

  static initialize() {
    AccountInsightsContext.setContext(AccountInsightsContext.#SKILL_CLIENT_ID_KEY, process.env.ALEXA_SKILL_CLIENT_ID);
    AccountInsightsContext.setContext(AccountInsightsContext.#SKILL_REDIRECT_URL_KEY, process.env.ALEXA_SKILL_REDIRECT_URL);
  }

  static getAlexaSkillClientId() {
    return AccountInsightsContext.getContext(
      AccountInsightsContext.#SKILL_CLIENT_ID_KEY,
      '[Please provide the Alexa skill client id]'
    );
  }

  static getAlexaSkillRedirectUrl() {
    return AccountInsightsContext.getContext(
      AccountInsightsContext.#SKILL_REDIRECT_URL_KEY,
      '[Please provide the Alexa skill redirect url]'
    );
  }
}
