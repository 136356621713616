import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { ApplicationContext } from 'core.ui-module_site-security'
import App from './App';
import './iconfont.font';
import '../scss/site.scss';
import siteJson from '../json/site.json';
import 'iframe-resizer/js/iframeResizer.contentWindow';

const container = document.getElementById('root');
const root = createRoot(container);
ApplicationContext.initialize(siteJson);
if (typeof root !== 'undefined' && root != null) {
  root.render(<App />);
}

