import React, { Component } from 'react';
import { SecureApp } from 'core.ui-module_site-security';
import PageLoader from './components/PageLoader';
import site from '../json/site.json';
import ContactUs from './layout/header/ContactUs';
import { AccountInsightsContext } from './api/AccountInsights/AccountInsightsContext';

export default class App extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    AccountInsightsContext.initialize();
  }

  render() {
    return (
      <div className="site">
        <SecureApp
          loader={PageLoader}
          site={site}
          contactUs={<></>}
          loginLink={<></>}
          logoutLink={<></>}
          // welcome={<></>}
        />
      </div>
    );
  }
}
